import consumer from "channels/consumer";

class CertificateSubscription {
  constructor(quizId) {
    this.quizId = quizId;
  }

  setup() {
    this.checkCertificateStatus().then((status) => {
      if (status.attached) {
        this.updateUI(status.path);
      } else {
        this.setupSubscription();
      }
    });
  }

  checkCertificateStatus() {
    return fetch(`/quizzes/${this.quizId}/certificate_status`).then(
      (response) => response.json()
    );
  }

  setupSubscription() {
    consumer.subscriptions.create(
      {
        channel: "CertificateChannel",
        quiz_id: this.quizId,
      },
      {
        received: (data) => {
          this.updateUI(data.certificate_path);
        },
      }
    );
  }

  updateUI(path) {
    const button = document.querySelector("[data-track-certificate]");
    const link = document.querySelector("#download-certificate-btn");

    if (button) button.classList.add("d-none");
    if (link) {
      link.href = path;
      link.classList.remove("d-none");
    }
  }
}

function ready() {
  const button = document.querySelector("[data-track-certificate]");
  if (button) {
    const quizId = button.dataset.trackCertificate;
    const subscription = new CertificateSubscription(quizId);
    subscription.setup();
  }
}

document.addEventListener("turbolinks:load", ready);
