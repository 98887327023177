import $ from 'jquery'

class Quiz {
  constructor () {
    this.currentStep = null;
    this.steps = [];
    this.container = null;

    this.back = this.back.bind(this);
    this.setup = this.setup.bind(this);
    this.stage = this.setStage.bind(this);
  }

  setup () {
    this.container = $('#steps-container');
    this.errorContainer = $('#step-errors');
    $("#back_step").click(this.back);
    bind_resize();
  }

  renderStep ({ template, stage, id }) {
    this.errorContainer.addClass('d-none')

    if (this.currentStep) {
      this.currentStep.template.removeClass('current-step').removeClass('new-step').addClass('previous-step');
      this.steps.push(this.currentStep);
    }

    this.currentStep = {
      template: $(template),
      stage: stage,
      id: id
    };

    this.currentStep.template.appendTo(this.container).addClass(
      'current-step').addClass('new-step');
    this.setStage(this.currentStep.stage);

    bind_positive_number_check();
    bind_recycling_if_any();
    adjust_content_height();
    bind_engine_size_if_any();
  }

  renderErrors (message) {
    this.errorContainer.text(message).removeClass('d-none')
  }

  setStage(stage){
    $('footer').removeClass();
    $('footer').addClass(stage);
    if(stage == 'personal'){
      $('.item-category').show();
      $('.item-category#results').hide();
    } else {
      $('.item-category').hide();
      $('.item-category#'+stage).show();
      $('.item-category#'+stage).removeClass('next');
      $('.item-category'+' .fas').show();
      $('.item-category#'+stage+' .fas').hide();
      $('.item-category#'+stage).next().addClass('next');
      $('.item-category#'+stage).next().show();
    }
  }

  back () {
    if (this.steps.length) {
      const lastStep = this.steps.pop();
      const lastActiveStep = this.currentStep;
      lastActiveStep.template.removeClass('current-step').removeClass('previous-step').addClass('exit');
      lastStep.template.addClass('current-step');
      this.currentStep = lastStep;
      this.setStage(this.currentStep.stage);
      this.errorContainer.addClass('d-none');
      adjust_content_height();
      setTimeout(function(){lastActiveStep.template.remove()}, 500);
    }else {
      window.location = '/';
    }
  }
}

window.quiz = new Quiz();
window.$ = $;

$(document).on("turbolinks:load", function(){
  quiz.setup();
  bind_tons_to_total();
  update_total_field();
});

function verify_dont_recycle(e){
  if ($(e)[0].checked){
    $('.recycling-material').prop('checked', false);
  } 
}
function verify_recycling_material(e){
  if($(e)[0].checked){
    $('.dont-recycle').prop('checked', false)
  }
}

function check_for_negatives(e){
  if(!($(e).val() == '')){
    $(e).val(Math.max($(e).val(),0));
  }
}

function adjust_content_height(){
  $('#steps-container').height($('.current-step').outerHeight());
}

function update_engine_size_input(e){
  $('.engine_size').removeClass('d-none');
  var v = $(e).val();
  $('.engine-size-variant').addClass('d-none');
  if (v == 'motorcycle'){
    $('#engine-volume').removeClass('d-none');
  }else if (v == 'car'){
    $('#cylinder-count').removeClass('d-none');
  }
  adjust_content_height();
}

function bind_recycling_if_any(){
  if($('.dont-recycle').length){
    $('.dont-recycle').change(function(){
      verify_dont_recycle(this);
    });
  }
  if($('.recycling-material').length){
    $('.recycling-material').change(function(){
      verify_recycling_material(this);
    });
  }
}

function bind_positive_number_check(){
  $('.positive-number').change(function(){
    check_for_negatives(this);
  });
}

function update_total_field(){
  $('#proposed-total').val(($('#compensated-tons').val() * 264.6).toFixed(2));
}
function bind_tons_to_total(){
  $('#compensated-tons').change(function(){
    update_total_field();
  });
}

function bind_resize() {
  $(window).resize(function(){
    adjust_content_height();
  });
}
function bind_engine_size_if_any() {
  var vehicle_selection_element = $('input[name="quiz[vehicle_type]"]');
  if(vehicle_selection_element.length){
    vehicle_selection_element.change(function(){
      update_engine_size_input(this);
    });
  }
}
