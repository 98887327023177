// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "@fortawesome/fontawesome-free/css/all";
import "channels";
import 'bootstrap';

import "components/quiz";
import "components/quiz-confirmation";
import "components/payment-form";
import "components/charts";
import "components/results";

import "stylesheets/application";

Rails.start()
Turbolinks.start()
ActiveStorage.start()
