import $ from 'jquery';

function ready() {
  const element = document.querySelector("#results-mail-send");
  if (element){
    element.addEventListener("ajax:success", (event) => {
      success(this);
    });
    element.addEventListener("ajax:send", (event) => {
      sending(this);
    });
    element.addEventListener("ajax:error", () => {
      error(this);
    });
  }
}

function sending(e) {
  hide_notifications();
  $(e).find('.sending').removeClass('d-none');
  $(e).find('button').attr('disabled', true);
}
function success(e) {
  $(e).find('.sending').addClass('d-none');
  $(e).find('button').attr('disabled', false);
  $(e).find('.success-message').removeClass('d-none');
  setTimeout(function() { hide_notifications() }, 2000);
}

function error(e) {
  $(e).find('.sending').addClass('d-none');
  $(e).find('button').attr('disabled', false);
  $(e).find('.error-message').removeClass('d-none');
  setTimeout(function() { hide_notifications() }, 2000);
}

function hide_notifications() {
  $('.notification').addClass('d-none');
}

$(document).on('turbolinks:load', ready);
