import Chart from 'chart.js/auto';
window.chartCreated = false;

document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('scoreChart')) {
    var scoreChartElement = document.getElementById('scoreChart')
    var ctx = scoreChartElement.getContext('2d');
    var scoreChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: JSON.parse(ctx.canvas.dataset.labels),
        datasets: [{
          data: JSON.parse(ctx.canvas.dataset.data),
          backgroundColor: JSON.parse(ctx.canvas.dataset.colors)
        }]
      },
      options: {
        plugins:{
          legend: {
            position: 'right',
            labels: {
              usePointStyle: true,
              pointStyle: 'circle'
            }
          }
        },
        animation: {
          onComplete: function(animation){
            document.getElementById('chart-base64-data').value = this.toBase64Image();
          }
        }
      }
    });
    

  }
  if (document.getElementById('comparisonChart')) {
    var ctx = document.getElementById('comparisonChart').getContext('2d');
    var comparisonChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: JSON.parse(ctx.canvas.dataset.labels),
        datasets: [{
          data: JSON.parse(ctx.canvas.dataset.data),
          backgroundColor: JSON.parse(ctx.canvas.dataset.color)
        }]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              title: (context) => {
                return context[0].label.replaceAll(',', ' '); 
              }
            }
          },
          legend: {
            display: false
          }
        }
      }
    });
  }
})
